import { MOUNTED } from "single-spa";
import { StateKey, getStateValue } from "./state.service";
import { createAppDom, getAppContainer } from "./dom.service";

function spaEventHandler(
  event: CustomEvent<{ newAppStatuses: Record<string, string> }>
) {
  const microShellAppName = getStateValue<string>(
    StateKey.MICRO_SHELL_APP_NAME
  );
  const microShell = getAppContainer(microShellAppName);
  if (!microShell) return;

  const apps = [
    ...getStateValue<string[]>(StateKey.MICRO_SHELL_APPS),
    ...getStateValue<string[]>(StateKey.MICRO_SHELL_APP_ALWAYS_ALIVE),
  ];
  for (const key in event.detail.newAppStatuses) {
    const status = event.detail.newAppStatuses[key];
    if (!apps.includes(key)) continue;
    if (status === MOUNTED) {
      createAppDom(microShellAppName, key);
    }
  }
}

export function registerBeforeMount() {
  window.addEventListener(
    "single-spa:before-mount-routing-event",
    spaEventHandler
  );
}
