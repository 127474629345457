import { environment } from "../environments/environment";
export async function getParams(): Promise<Params> {
  const params = await fetchParams(environment.paramsApi);

  return mapParams(params);
}

const fetchParams = (apiUrl: string): Promise<ParamsResponse> =>
  fetch(apiUrl).then((r) => r.json());

const mapParams = (params: ParamsResponse): Params => ({
  apps: [
    ...mapUtilities(params.utilities),
    ...mapMfsAlwaysAlive(params.transversalMfs),
    ...mapMfs(params.mfs),
  ],
  features: params.features,
});

const mapUtilities = (utilities: ParamsResponse["utilities"]) =>
  Object.entries(utilities).map<App>(([name, url]) => ({
    name,
    url,
    type: "utility",
  }));

const mapMfsAlwaysAlive = (mfs: ParamsResponse["transversalMfs"]) =>
  Object.entries(mfs).map<App>(([name, url]) => ({
    name,
    url,
    type: "mf-always-alive",
  }));

const mapMfs = (mfs: ParamsResponse["mfs"]) =>
  Object.entries(mfs).map<App>(
    ([name, { paths, url, generatedProps, extraProps }]) => ({
      name,
      paths,
      url,
      type: "mf",
      generatedProps,
      extraProps,
    })
  );
