import { navigateToUrl } from "single-spa";
import {
  activityFnCreator,
  addInternApplication,
} from "../../services/single-spa.service";
import layout from "./channel-mock.html";
import { getBasePath } from "../../services/location.service";
import {
  APP_NAME,
  APP_PATHS,
  CHANNEL_EVENT_BUS,
  LOADER_SELECTOR,
  NEXT_PATH,
} from "./channel-mock.constants";
import { createAppDom, getAppContainer } from "../../services/dom.service";
import { getStateValue, StateKey } from "../../services/state.service";
import { getEventBus } from "../../services/event-bus.service";

let appLayout: any = layout;
export const setLayout = (l: string) => (appLayout = l);

export function regiterChannelMock() {
  addInternApplication({
    app: createApp(),
    activeWhen: activityFnCreator(getBasePath(), { paths: APP_PATHS }),
    name: APP_NAME,
  });
}

function createApp() {
  return {
    bootstrap,
    mount,
    unmount,
  };
}

export const bootstrap = () => Promise.resolve();

export async function mount() {
  const parentName = getStateValue<string>(StateKey.MICRO_SHELL_APP_NAME);
  createAppDom(parentName, APP_NAME);
  const container = getAppContainer(APP_NAME);
  container.innerHTML = appLayout;
  const form = container.querySelector("form")!;
  form.addEventListener("submit", submitHandler(form));
  const loader: Element & { active: boolean } =
    document.querySelector(LOADER_SELECTOR);
  loader.active = false;
  const eventbus = getEventBus();
  eventbus.pendingRequests$.next(0);
}

export const unmount = () => Promise.resolve();

export const submitHandler = (form: HTMLFormElement) => (e: SubmitEvent) => {
  e.preventDefault();
  const formData = new FormData(form);
  const data = Object.fromEntries(formData.entries());
  if (!Object.values(data).every(Boolean)) return;
  window.dispatchEvent(
    new CustomEvent(CHANNEL_EVENT_BUS.EVENTS.DISPATCH, {
      detail: {
        topicName: CHANNEL_EVENT_BUS.TOPICS.BASIC,
        topicValue: { value: data },
        isStored: true,
      },
    })
  );
  navigateToUrl(getBasePath() + NEXT_PATH);
};
