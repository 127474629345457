import { setBootstrapValues } from "./services/state.service";
import { registerBeforeMount } from "./services/handlers.service";
import { registerApps } from "./services/apps.service";
import { getEventBus, loadEventBus } from "./services/event-bus.service";
import { getBasePath } from "./services/location.service";
import { navigateToUrl } from "single-spa";
import "./core.scss";
import singleSpaCss from "single-spa-css";
import { BehaviorSubject, Subscription } from "rxjs";

export const allReady = new BehaviorSubject(false);

export async function bootstrap(props) {
  setBootstrapValues(props);
  registerApps()
    .then(() => loadEventBus())
    .then(() => allReady.next(true));
  registerBeforeMount();
}

export const cssLifecycles = singleSpaCss({
  // required: a list of CSS URLs to load
  // can be omitted if webpackExtractedCss is set to true, do not specify Webpack extracted css files here
  cssUrls: [],

  // optional: defaults to false. This controls whether extracted CSS files from Webpack
  // will automatically be loaded. This requires using the ExposeRuntimeCssAssetsPlugin,
  // which is documented below.
  webpackExtractedCss: true,

  // optional: defaults to true. Indicates whether the <link> element for the CSS will be
  // unmounted when the single-spa microfrontend is unmounted.
  shouldUnmount: true,

  // optional: defaults to 5000. The number of milliseconds to wait on the <link> to load
  // before failing the mount lifecycle.
  timeout: 5000,

  // optional: defaults to a standard <link rel="stylesheet" href="/main.css"> element
  // Customize the creation of the link element that is used by single-spa-css by providing a
  // function. For example, for setting the cross-origin or other HTML attributes on the <link>
  createLink(url) {
    const linkEl = document.createElement("link");
    linkEl.rel = "stylesheet";
    linkEl.href = url;
    return linkEl;
  },
});

export const defaultMountParcel = () => null;

let subscriptions = new Subscription();

export const mount = async () => {
  cssLifecycles.mount({
    name: "core-styles",
    singleSpa: null,
    mountParcel: defaultMountParcel,
  });

  subscriptions = allReady.subscribe((ready) => {
    if (ready) {
      const eventbus = getEventBus();
      eventbus.pendingRequests$.next(1);
      const sub = new Subscription();
      sub.add(
        eventbus.clientDataEvent$.subscribe((d) => {
          if (d?.authentication?.token) {
            sub.unsubscribe();
            const basePath = getBasePath();
            const path = window.location.pathname.replace(/\/$/, "");
            if (basePath === path) navigateToUrl(`${basePath}/datos-iniciales`);
          }
        })
      );
    }
  });
};

export async function unmount(props) {
  const eventbus = getEventBus();
  eventbus.resetClientData$.next();
  cssLifecycles.unmount(props);
  subscriptions.unsubscribe();
}
