export function addImportMap(newMap: {
  imports: Record<string, string>;
}): Promise<undefined> {
  const script = document.createElement("script");
  script.type = "systemjs-importmap";
  script.textContent = JSON.stringify(newMap);
  document.head.appendChild(script);
  return (System as any).prepareImport(true);
}

export function createAppDom(parentName: string, appName: string) {
  const parent = getAppContainer(parentName);
  if (parent.parentElement.contains(getAppContainer(appName))) return;
  const app = document.createElement("div");
  app.id = domAppId(appName);
  parent.after(app);
}

export const domAppId = (appName: string) =>
  `single-spa-application:${appName}`;
export const getAppContainer = (appName: string) =>
  document.getElementById(domAppId(appName));
