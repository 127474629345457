import { getBasePath } from "../services/location.service";
import { map, Observable } from "rxjs";
import { environment } from "../environments/environment";
import { getEventBus } from "../services/event-bus.service";

export const uploadDocuments = (): UploadDocumentsProps => {
  const clientData = getEventBus().clientDataEvent$;
  const clientDataBus = clientData.pipe(
    map((data) => ({
      channel: "FCW",
      token: data.authentication?.token,
      transactionId: data.authentication?.transactionId,
      document: data.basicData?.document,
      documentType: data.basicData?.documentType,
      creditNumber: data.filing?.nroCredit,
      additional: {
        isNewClient: data.basicData?.crmIndicator === "N",
        route: data.viability?.route,
        destination: data.viability?.destination,
      },
    }))
  );
  return {
    generalParams: {
      basePath: getBasePath(),
      mfPath: "/cargar-documentos/",
      leaveErrorPath: "/error",
      leavePath: "/desborde/cargar-despues",
      product: "fcw-VI",
      successPath: getSuccessPath(clientData),
    },
    clientData: clientDataBus,
    screenParams: getScreenParams(),
  };
};

const getScreenParams = (): Observable<ScreenParams> => {
  return new Observable<ScreenParams>((subs) => {
    const controller = new AbortController();
    fetchParams(controller)
      .then((data) => {
        subs.next(data);
        subs.complete();
      })
      .catch(() => {
        subs.complete();
      });
    return {
      unsubscribe: () => {
        controller.abort();
      },
    };
  });
};

const fetchParams = (controller: AbortController): Promise<ScreenParams> =>
  fetch(environment.uploadDocumentsParamsApi, {
    signal: controller.signal,
  }).then((r) => r.json());

const getSuccessPath = (clientData) => {
  return clientData.value.viability?.route != 1
    ? "/desborde/fin-flujo"
    : "/preaprobado";
};
